import React from 'react';
import { SearchIcon } from './search-icon';

export const SearchIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<SearchIcon iconWidth="80" />'}</p>
      <SearchIcon iconWidth="80" />
    </div>
  );
};

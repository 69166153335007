import React from 'react';
import { Button } from './button';

export const ButtonDemo: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        background: '#ddd',
        padding: '10px'
      }}
    >
      {'<Button> Component'}
      <Button
        color="darkGreen"
        variant="filled"
        size="large"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="darkGreen" variant="filled" size="large" onClick='}
      </Button>
      <Button
        color="darkGreen"
        variant="filled"
        size="small"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="darkGreen" variant="filled" size="small" onClick='}
      </Button>
      <Button
        color="darkGreen"
        variant="outlined"
        size="large"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="darkGreen" variant="outlined" size="large" onClick='}
      </Button>
      <Button
        color="darkGreen"
        variant="outlined"
        size="small"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="darkGreen" variant="outlined" size="small" onClick='}
      </Button>

      <Button
        color="white"
        variant="filled"
        size="large"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="white" variant="filled" size="large" onClick='}
      </Button>
      <Button
        color="white"
        variant="filled"
        size="small"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="white" variant="filled" size="small" onClick='}
      </Button>
      <Button
        color="white"
        variant="outlined"
        size="large"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="white" variant="outlined" size="large" onClick='}
      </Button>
      <Button
        color="white"
        variant="outlined"
        size="small"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="white" variant="outlined" size="small" onClick='}
      </Button>

      <Button
        color="sage"
        variant="filled"
        size="large"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="sage" variant="filled" size="large" onClick='}
      </Button>
      <Button
        color="sage"
        variant="filled"
        size="small"
        onClick={() => null}
        trackingLabel="test tracking"
      >
        {'color="sage" variant="filled" size="small" onClick='}
      </Button>

      <Button
        color="darkGreen"
        variant="filled"
        size="large"
        destination="/"
        trackingLabel="test tracking"
      >
        {'color="darkGreen" variant="filled" size="large" destination="/" --> '}
        I'm a Link
      </Button>
    </div>
  );
};

import React, { FC } from 'react';
import styles from './rounded-checkbox.module.css';

interface RoundedCheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  onClick?: () => void;
}

export const RoundedCheckbox: FC<RoundedCheckboxProps> = ({
  checked = false,
  onClick
}) => {
  return (
    <span className={styles.container}>
      <input type="checkbox" checked={checked} onChange={onClick} />
      <span className={styles.checkmark}></span>
    </span>
  );
};

import React from 'react';
import { StatisticCarousel } from './statistic-carousel';
import { StatisticCarouselDemoData } from './statistic-carousel-demo-data';

export const StatisticCarouselDemo = () => {
  return (
    <div className="my-lg">
      <StatisticCarousel {...StatisticCarouselDemoData} />
    </div>
  );
};

import React from 'react';
import { FacebookIcon } from './facebook-icon';

export const FacebookIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<FacebookIcon iconWidth="100" />'}</p>
      <FacebookIcon iconWidth="100" />
    </div>
  );
};

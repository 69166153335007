import React from 'react';
import { CloseIcon } from './close-icon';

export const CloseIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<CloseIcon iconWidth="100" />'}</p>
      <CloseIcon iconWidth="100" />
    </div>
  );
};

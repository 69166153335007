import React from 'react';
import { MenuIcon } from './menu-icon';

export const MenuIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<MenuIcon iconWidth="100" />'}</p>
      <MenuIcon iconWidth="100" />
    </div>
  );
};

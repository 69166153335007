import React from 'react';
import { YoutubeIcon } from './youtube-icon';

export const YoutubeIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<YoutubeIcon iconWidth="100" />'}</p>
      <YoutubeIcon iconWidth="100" />
    </div>
  );
};

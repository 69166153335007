import React from 'react';
import { ProcessCarousel } from './process-carousel';
import processCarouselContent from '../../content/why-it-works/process-carousel-data';

export const ProcessCarouselDemo = () => {
  return (
    <div>
      <h1>Organism - Process Carousel</h1>
      <ProcessCarousel slides={processCarouselContent.items} />
    </div>
  );
};

import React from 'react';
import { RoundedCheckbox } from '.';

export const RoundedCheckboxDemo: React.FC = () => {
  const [checked, setChecked] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 40
      }}
    >
      {'<RoundedCheckbox />'}
      <RoundedCheckbox onClick={() => setChecked(!checked)} checked={checked} />
    </div>
  );
};

import React, { FC, useState } from 'react';
import { ProductTile } from './product-tile';
import { Row, Column } from '../..';

export const ProductTileDemo: FC = () => {
  const [multiple, setMultiple] = useState(true);
  return (
    <div className="py-xl bg-dark-green">
      {'<ProductTile>'}
      <div className="flex flex-column py-md">
        Multiple product tile
        <input type="checkbox" onChange={() => setMultiple(!multiple)} />
      </div>
      <Row
        cols={multiple ? 19 : 6}
        offset={multiple ? 5 : 3}
        gap={multiple ? 0 : 3}
      >
        <Column>
          <ProductTile
            image={{
              alt: 'human',
              src:
                'https://cdn.sanity.io/images/mur2ep80/production/a41a0a8d6cccb41af5d8fa857eb9c264155bbb45-1728x2157.png'
            }}
            price={'72.50'}
            title={'Parsley Prenatal'}
            single={multiple}
            ctaTrackingLabel="test tracking product tile demo"
          />
        </Column>
        {!multiple && (
          <Column>
            <ProductTile
              image={{
                alt: 'human',
                src:
                  'https://cdn.sanity.io/images/mur2ep80/production/a41a0a8d6cccb41af5d8fa857eb9c264155bbb45-1728x2157.png'
              }}
              price={'72.50'}
              title={'Parsley Prenatal'}
              single={false}
              ctaTrackingLabel="test tracking product tile demo"
            />
          </Column>
        )}
      </Row>
    </div>
  );
};

import React from 'react';
import { BlogPostTagsFooter, Text } from '../..';
import content from '../../content/blog/blog-post-tags-footer.json';

export const BlogPostTagsFooterDemo = () => {
  return (
    <div className="mb-sm px-sm">
      <Text variant="h2a">Blog Post Tags Footer</Text>
      <BlogPostTagsFooter tags={content.tags} />
    </div>
  );
};

import React from 'react';
import { StandardCarousel } from '../..';
import Content from '../../content/why-it-works/conditions-carousel-we-helped-thousands.json';

export const StandardCarouselDemo = () => {
  return (
    <StandardCarousel
      eyebrow={Content.eyebrow}
      heading={Content.heading}
      slides={Content.slides}
    />
  );
};

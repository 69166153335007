import React, { useState } from 'react';
import { Text } from '../../atoms/text/text';
import { TextLockup } from '../../atoms/text-lockup/text-lockup';
import { Button } from '../../atoms/button/button';

import { BottomSheet } from './bottom-sheet';
import './bottom-sheet-demo.scss';

export const BottomSheetDemo: React.FC = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setToggle(!toggle)}
        color="sage"
        className="w-full mt-md"
        trackingLabel="half and half with text lock up content and numbered list"
      >
        {toggle ? 'close bottom sheet' : 'open bottom sheet'}
      </Button>

      <BottomSheet isOpen={toggle} onClose={() => setToggle(false)}>
        <div className="bottom-sheet-container">
          <div>
            <TextLockup>
              <Text variant="h6a" className="mb-sm responsive-text">
                Lorem Ipsum
              </Text>
              <Text variant="p2a" className="responsive-text">
                Lorem ipsum mendem
              </Text>
            </TextLockup>
          </div>

          <div className="aetna-button-wrapper">
            <div>
              <Button
                color="sage"
                className="w-full mt-md"
                trackingLabel="half and half with text lock up content and numbered list"
              >
                Lorem
              </Button>
            </div>
            <div>
              <Button
                color="darkGreen"
                variant="outlined"
                className="mt-md w-full"
                trackingLabel="half and half with text lock up content and numbered list"
              >
                Lorem Ipsum
              </Button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

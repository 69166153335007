import React, { FC } from 'react';
import { DoctorListFilters } from './doctor-list-filters';

export const DoctorListFiltersDemo: FC = () => {
  return (
    <div className="bg-dark-green">
      <DoctorListFilters
        roles={[
          { label: 'Medical Providers and Health Coaches', value: '1' },
          { label: 'Medical Providers', value: '2' },
          { label: 'Health Coaches', value: '3' }
        ]}
        locations={[
          { label: 'New York', value: '1' },
          { label: 'Los Angeles', value: '2' },
          { label: 'Alabama', value: '3', online: true }
        ]}
      />
      ;
    </div>
  );
};

import React from 'react';
import { Rating } from './rating';

export const RatingDemo = () => {
  return (
    <div className="mb-md mt-lg">
      <p>rating demo</p>
      <Rating iconSize={22} rating={4} />
    </div>
  );
};

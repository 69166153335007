import React from 'react';

import { BlogHero, BlogHeroProps } from './blog-hero';
import { HeroTextOnly } from '@ph-bit/marketing.common.heroes.text-only';
import { BaseChip } from '@ph-bit/design.ui.chips.base-chip';
import { SanityHeroTextOnlyModule } from '../../../../graphql-types';

export type HeroTextOnlyModuleProps = SanityHeroTextOnlyModule;

export enum HeroTextOnlyTheme {
  forest = 'FOREST',
  gradient = 'GRADIENT'
}

const heroData: BlogHeroProps = {
  blogs: [
    {
      id: '1',
      tag: {
        name: 'Optimization',
        variant: 'baby-blue'
      },
      headline: '6 Hormones You Can Balance Using Exercise',
      cta: {
        text: 'Read Article',
        ctaTrackingLabel: 'test tracking blog hero demo'
      },
      image: {
        src:
          'https://www.experimenta.es/wp-content/uploads/2018/10/David-Bowie-red.jpg',
        alt: ''
      }
    },
    {
      id: '2',
      tag: {
        name: 'Optimization',
        variant: 'baby-blue'
      },
      headline: '6 Hormones You Can Balance Using Exercise',
      cta: {
        text: 'Read Article',
        ctaTrackingLabel: 'test tracking blog hero demo'
      },
      image: {
        src:
          'https://www.experimenta.es/wp-content/uploads/2018/10/David-Bowie-red.jpg',
        alt: ''
      }
    },
    {
      id: '3',
      tag: {
        name: 'Optimization',
        variant: 'baby-blue'
      },
      headline: '6 Hormones You Can Balance Using Exercise',
      cta: {
        text: 'Read Article',
        ctaTrackingLabel: 'test tracking blog hero demo'
      },
      image: {
        src:
          'https://www.experimenta.es/wp-content/uploads/2018/10/David-Bowie-red.jpg',
        alt: ''
      }
    },
    {
      id: '4',
      tag: {
        name: 'Optimization',
        variant: 'baby-blue'
      },
      headline: '6 Hormones You Can Balance Using Exercise',
      cta: {
        text: 'Read Article',
        ctaTrackingLabel: 'test tracking blog hero demo'
      },
      image: {
        src:
          'https://www.experimenta.es/wp-content/uploads/2018/10/David-Bowie-red.jpg',
        alt: ''
      }
    }
  ]
};

export const BlogHeroDemo = () => {
  return (
    <div className="mt-md">
      <BlogHero {...heroData} />
      <HeroTextOnly
        theme={HeroTextOnlyTheme.gradient}
        headline={`Yes, we accept insurance.`}
        supportingText="Real healing through personalized care—now even more accessible."
        buttonText="Learn About Insurance"
        buttonOnClick={() => alert('boop')}
        linkText="Join with an out-of-network plan"
        linkOnClick={() => alert('boop')}
        disclaimerText="If Parsley Health is in network with your health plan, insurance may help cover the cost of clinical appointments. It does not cover our program fee."
      >
        <div className="flex flex-wrap gap-4">
          <BaseChip
            interactive={false}
            background="bg-ph-mint"
            size="medium"
            label="Aetna"
          />
          <BaseChip
            interactive={false}
            background="bg-ph-mint"
            size="medium"
            label="United Health"
          />
          <BaseChip
            interactive={false}
            background="bg-ph-mint"
            size="medium"
            label="BCBS"
          />
        </div>
      </HeroTextOnly>
    </div>
  );
};

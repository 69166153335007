import React from 'react';
import { TextLockup } from './text-lockup';
import { Text } from '../text/text';
import { Button } from '../button/button';
import JSONData from '../../content/text-lockup/text-lockup-lorem-ipsum.json';

export const TextLockupDemo = () => (
  <TextLockup alignment={JSONData.alignment}>
    <Text variant="p3b" className="text-lockup-eyebrow" color="dark-green">
      {JSONData.eyebrow}
    </Text>
    <Text variant="h2a" className="text-lockup-headline" color="dark-green">
      {JSONData.headline}
    </Text>
    <Text variant="p2a" className="text-lockup-description" color="dark-green">
      {JSONData.description}
    </Text>
    <div className="text-lockup-buttons-container">
      <Button
        onClick={() => null}
        variant="filled"
        color="darkGreen"
        trackingLabel="test tracking"
      >
        {JSONData.primaryCtaText}
      </Button>
      <Button
        onClick={() => null}
        variant="outlined"
        color="white"
        trackingLabel="test tracking"
      >
        {JSONData.secondaryCtaText}
      </Button>
    </div>
  </TextLockup>
);

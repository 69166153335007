import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Dropdown } from '../../atoms/dropdown/dropdown';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button/button';
import { useOnToggleScrolling } from '../../../hooks/use-on-toggle-scrolling';
import './hidden-filter.scss';
import { Row, Column } from '../../atoms/grid';
import { Option } from '../../atoms/option/option';
import { FilterIcon } from '../../atoms/icons/filter-icon/filter-icon';
import { useMask } from '../../../hooks/use-mask';
import { Chips } from '../../atoms/chips';

export type TSortBy = {
  label: string;
  value: string;
};

export type TFilterByItem = {
  label: string;
  value: string;
};

export type TFilterBy = {
  label: string;
  items: TFilterByItem[];
};

interface HiddenFilterProps {
  title?: string;
  sortBy?: TSortBy[];
  selectedSortBy?: TSortBy[];
  onSelectSortBy?: Function;
  filters?: TFilterBy[];
  selectedFilter?: TFilterByItem[];
  onSelectFilter?: Function;
  onClearFilter?: Function;
  firstCtaTrackingLabel: string;
  secondCtaTrackingLabel: string;
}

export const HiddenFilterPanel: FC<HiddenFilterProps> = ({
  title,
  sortBy,
  filters,
  selectedFilter,
  onSelectFilter,
  onClearFilter,
  onSelectSortBy,
  firstCtaTrackingLabel,
  secondCtaTrackingLabel
}) => {
  const [isOpen, onToggleOpen] = useOnToggleScrolling(false);
  const [isShowAllSelected, setIsShowAllSelected] = useState(false);
  const { setMask } = useMask();

  useEffect(() => {
    setMask(isOpen);
  }, [isOpen, setMask]);

  const toggleShowAll = () => {
    if (!isShowAllSelected && onClearFilter) {
      onClearFilter();
    }
    setIsShowAllSelected(selected => !selected);
  };

  return (
    <Column>
      <Row className="hidden-filter-container">
        <Dropdown
          heading={
            <Button
              color="darkGreen"
              variant="outlined"
              size="small"
              className="mr-tiny"
              trackingLabel={firstCtaTrackingLabel}
            >
              <FilterIcon iconWidth={36} />
              {title}
            </Button>
          }
          isOpen={isOpen}
          onToggleOpen={onToggleOpen}
          className={clsx(`hidden-filter-dropdown`)}
          optionsContainerClass="bg-ivory hidden-filter-wrapper-content"
          closeIconContainerClass="d-flex text-white"
        >
          <div className="hidden-filter-popup-container">
            {sortBy?.length > 0 && (
              <div>
                <Text variant="p1a" className="mb-sm pb-xtiny filter-line">
                  Sort By
                </Text>
                {sortBy.map((o, i: number) => {
                  return (
                    <Option
                      option={o}
                      key={`value+${i}`}
                      onSelectOption={() => onSelectSortBy(o)}
                    ></Option>
                  );
                })}
              </div>
            )}
            {filters?.length > 0 && (
              <div className="mt-sm mb-sm">
                <Text variant="p1a" className="mb-sm pb-xtiny filter-line">
                  Filter By
                </Text>

                <div className="mb-md">
                  <Option
                    option={{
                      value: 'show-all',
                      label: 'Show All',
                      isActive: isShowAllSelected
                    }}
                    key="show-all-options"
                    onSelectOption={() => toggleShowAll()}
                  ></Option>
                </div>

                {filters.map((c, i) => {
                  return (
                    <div key={i}>
                      <Text variant="u2">{c.label}</Text>
                      <div key={`options-${i}`} className="mt-xxs mb-md">
                        {c.items.map((o, j) => {
                          return (
                            <Option
                              option={o}
                              key={`value+${j}`}
                              onSelectOption={() => {
                                onSelectFilter(o);
                                setIsShowAllSelected(false);
                              }}
                            ></Option>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <Button
              color="sage"
              variant="filled"
              size="large"
              onClick={() => onToggleOpen()}
              className="w-full mt-auto"
              trackingLabel={secondCtaTrackingLabel}
            >
              Apply
            </Button>
          </div>
        </Dropdown>

        <div className="selected-filter">
          {selectedFilter?.map(item => (
            <div className="mr-sm">
              <Chips
                onClose={() => onSelectFilter(item)}
                key={`filter-chip-${item.value}`}
                label={item.label}
              />
            </div>
          ))}
          {selectedFilter?.length > 1 && (
            <div onClick={() => onClearFilter()}>
              <Text className="clear-all-filter" color="sage" variant="u1b">
                Clear All
              </Text>
            </div>
          )}
        </div>
      </Row>
    </Column>
  );
};

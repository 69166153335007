import React from 'react';
import { ImageType } from '../../atoms/image/types';
import { Statistic } from './statistic';

export const StatisticDemo: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      <div style={{ width: '600px', height: '500px' }}>
        <Statistic
          className="bg-gradient-green"
          type={ImageType.RECTANGLE}
          data={{
            value: '80+',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
          }}
        />
      </div>
      <div style={{ width: '500px', height: '650px' }}>
        <Statistic
          className={'bg-gradient-sherbed'}
          type={ImageType.RECTANGLE_XL}
          data={{
            value: '90+',
            description: 'Some description here about the value'
          }}
        />
      </div>
      <div style={{ width: '400px', height: '400px' }}>
        <Statistic
          className={'bg-clover'}
          type={ImageType.CIRCLE}
          data={{
            value: '70%',
            description: 'Lorem ipsum dolor sit amet, consectetur '
          }}
        />
      </div>
      <div style={{ width: '400px', height: '400px' }}>
        <Statistic
          color={'white'}
          type={ImageType.ROUND}
          data={{ value: '33+', description: 'Some description' }}
        />
      </div>
    </div>
  );
};

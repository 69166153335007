import React, { FC, useState } from 'react';
import { useMask } from '../../../hooks/use-mask';
import { Button } from '../button/';

export const MaskDemo: FC = () => {
  const { setMask } = useMask();
  const [show, setShow] = useState(false);

  const handleButton = () => {
    setShow(!show);
    setMask(show);
  };
  return (
    <div className="mask-active p-md">
      <h1>Mask Demo</h1>
      <Button
        color={'darkGreen'}
        variant="filled"
        type="button"
        size="small"
        onClick={() => handleButton()}
        trackingLabel="test tracking"
      >
        Click me!
      </Button>
    </div>
  );
};

import React from 'react';

import { ArticleCard, ArticleCardProps } from './article-card';
import { Text } from '../../atoms/text/text';
import { baseSanityNode } from '../../../redesign/content/base-sanity-node';

const article: ArticleCardProps = {
  id: 'article1',
  ...baseSanityNode,
  image: {
    ...baseSanityNode,
    asset: {
      ...baseSanityNode,
      url:
        'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
    }
  },
  author: {
    ...baseSanityNode,
    profileImage: {
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
      },
      alt: ''
    }
  },
  tags: [
    {
      ...baseSanityNode,
      slug: { current: '' },
      title: 'Health Concerns'
    }
  ],
  title: 'We Need Healthy Hormones—Here’s Why and What You Can Do ',
  slug: { current: '' }
};

const article2: ArticleCardProps = {
  id: 'article2',
  ...baseSanityNode,
  image: {
    ...baseSanityNode,
    asset: {
      ...baseSanityNode,
      url:
        'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
    }
  },
  author: {
    ...baseSanityNode,
    profileImage: {
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
      },
      alt: ''
    }
  },
  tags: [
    {
      ...baseSanityNode,
      slug: { current: '' },
      title: 'Health Concerns'
    }
  ],
  title: 'How to Recognize and Treat Estrogen Dominance',
  slug: { current: '' }
};

export const ArticleCardDemo = () => {
  return (
    <div
      className="m-md flex flex-col justify-start"
      style={{ height: 'fit-content' }}
    >
      <div className="m-xs">
        <Text variant="h7a">Article Card</Text>
      </div>
      <div className="m-md flex justify-start align-end" style={{ gap: 10 }}>
        <ArticleCard {...article} />
        <ArticleCard {...article2} />
      </div>
    </div>
  );
};

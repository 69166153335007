import React from 'react';
import { StarIcon } from './star-icon';

export const StarIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<StarIcon iconSize="15" />'}</p>
      <StarIcon iconWidth="100" />
    </div>
  );
};

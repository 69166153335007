import React from 'react';

import { ArrowIconDemo } from '../../atoms/icons/arrow-icon/arrow-icon-demo';
import { CaretIconDemo } from '../../atoms/icons/caret-icon/caret-icon-demo';
import { CircleIconDemo } from '../../atoms/icons/circle-icon/circle-icon-demo';
import { CloseIconDemo } from '../../atoms/icons/close-icon/close-icon-demo';
import { CheckIconDemo } from '../../atoms/icons/check-icon/check-icon-demo';
import { MenuIconDemo } from '../../atoms/icons/menu-icon/menu-icon-demo';
import { MinusIconDemo } from '../../atoms/icons/minus-icon/minus-icon-demo';
import { PauseIconDemo } from '../../atoms/icons/pause-icon/pause-icon-demo';
import { PlayIconDemo } from '../../atoms/icons/play-icon/play-icon-demo';
import { PlusIconDemo } from '../../atoms/icons/plus-icon/plus-icon-demo';
import { FacebookIconDemo } from '../../atoms/icons/facebook-icon/facebook-icon-demo';
import { InstagramIconDemo } from '../../atoms/icons/instagram-icon/instagram-icon-demo';
import { PinterestIconDemo } from '../../atoms/icons/pinterest-icon/pinterest-icon-demo';
import { TwitterIconDemo } from '../../atoms/icons/twitter-icon/twitter-icon-demo';
import { YoutubeIconDemo } from '../../atoms/icons/youtube-icon/youtube-icon-demo';
import { HoverCircleIconDemo } from '../../atoms/icons/hover-circle-icon/hover-circle-icon-demo';
import { IllustrativeIconDemo } from '../../atoms/icons/illustrative-icon/illustrative-icon-demo';
import { FastCompanyIconDemo } from '../../atoms/icons/fast-company-icon/fast-company-icon-demo';
import { GoopIconDemo } from '../../atoms/icons/goop-icon/goop-icon-demo';
import { WomensHealthIconDemo } from '../../atoms/icons/womens-health-icon/womens-health-icon-demo';
import { VogueIconDemo } from '../../atoms/icons/vogue-icon/vogue-icon-demo';
import { WellGoodIconDemo } from '../../atoms/icons/well-good-icon/well-good-icon-demo';
import { TodayIconDemo } from '../../atoms/icons/today-icon/today-icon-demo';
import { StarIconDemo } from '../../atoms/icons/star-icon/star-icon-demo';
import { SearchIconDemo } from '../../atoms/icons/search-icon/search-icon-demo';

export const IconsDemo: React.FC = () => {
  return (
    <div>
      <ArrowIconDemo />
      <CaretIconDemo />
      <CircleIconDemo />
      <CloseIconDemo />
      <CheckIconDemo />
      <MenuIconDemo />
      <MinusIconDemo />
      <PauseIconDemo />
      <PlayIconDemo />
      <PlusIconDemo />
      <FacebookIconDemo />
      <InstagramIconDemo />
      <PinterestIconDemo />
      <TwitterIconDemo />
      <YoutubeIconDemo />
      <HoverCircleIconDemo />
      <IllustrativeIconDemo />
      <FastCompanyIconDemo />
      <GoopIconDemo />
      <WomensHealthIconDemo />
      <VogueIconDemo />
      <WellGoodIconDemo />
      <TodayIconDemo />
      <StarIconDemo />
      <SearchIconDemo />
    </div>
  );
};

import React from 'react';
import { ArrowIcon } from './arrow-icon';

export const ArrowIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<ArrowIcon direction="left" iconWidth="100" />'}</p>
      <ArrowIcon direction="left" iconWidth="100" />
      <p>{'<ArrowIcon iconWidth="30px" direction="right" color="clover" />'}</p>
      <ArrowIcon iconWidth="30px" direction="right" color="clover" />
      <p>{'<ArrowIcon iconWidth="40" direction="up" color="darkGreen" />'}</p>
      <ArrowIcon iconWidth="40" direction="up" color="darkGreen" />
      <p>{'<ArrowIcon iconWidth="40" direction="down" color="darkGreen" />'}</p>
      <ArrowIcon iconWidth="40" direction="down" color="darkGreen" />
      <p>{'<ArrowIcon iconWidth="48" direction="up" outlined />'}</p>
      <ArrowIcon iconWidth="48" direction="up" outlined />
      <p>{'<ArrowIcon iconWidth="48" direction="right" circled />'}</p>
      <ArrowIcon iconWidth="48" direction="right" circled />
    </div>
  );
};

import React from 'react';
import { Image } from './image';
import './style-demo.css';
import { ImageType } from './types';
import { baseSanityNode } from '../../../redesign/content/base-sanity-node';

export const ImageDemo: React.FC = () => {
  return (
    <div className="image-wrapper">
      <h3>Images </h3>
      <h4> ROUND </h4>
      <div className="img-sample-2 ">
        <Image
          blob
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          type={ImageType.ROUND}
        />
      </div>
      <div className="img-sample-1 ">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          type={ImageType.ROUND}
        />
      </div>
      <h4>RECTANGLE XL</h4>
      <div className="img-sample-3">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          type={ImageType.RECTANGLE_XL}
        />
      </div>
      <div className="img-sample-3">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          blob
          type={ImageType.RECTANGLE_XL}
        />
      </div>
      <div className="img-sample-2">
        <Image
          roundBorder={false}
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.RECTANGLE_XL}
        />
      </div>
      <h4> SQUARE </h4>
      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          blob
          type={ImageType.SQUARE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.SQUARE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          roundBorder={false}
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.SQUARE}
        />
      </div>
      <h4> Rectangle </h4>

      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          blob
          type={ImageType.RECTANGLE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.RECTANGLE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          roundBorder={false}
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.RECTANGLE}
        />
      </div>
      <h4>LARGE</h4>
      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          blob
          type={ImageType.LARGE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.LARGE}
        />
      </div>
      <div className="img-sample-1">
        <Image
          roundBorder={false}
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
          }}
          alt=""
          type={ImageType.LARGE}
        />
      </div>
    </div>
  );
};

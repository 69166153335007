import React from 'react';
import { WomensHealthIcon } from './womens-health-icon';

export const WomensHealthIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<WomensHealthIcon iconWidth="100" />'}</p>
      <WomensHealthIcon iconWidth="100" />
    </div>
  );
};

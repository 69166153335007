import React from 'react';
import { BlogPostInlineBanner, Text } from '../..';
import content from '../../content/blog/blog-post-inline-banner.json';

export const BlogPostInlineBannerDemo = () => {
  return (
    <div className="mb-sm px-sm">
      <Text variant="h2a">Blog Post Inline Banner</Text>
      <BlogPostInlineBanner title={content.headline} cta={content.cta} />
    </div>
  );
};

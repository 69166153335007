import React from 'react';
import { BlogNavigation, Text } from '../..';
import content from '../../content/blog/navigation.json';

export const BlogNavigationDemo = () => {
  return (
    <div>
      <Text variant="h2a">Blog navigation</Text>
      <BlogNavigation routes={content} />
    </div>
  );
};

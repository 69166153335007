import React from 'react';
import { CarouselStepIndicator } from './carousel-step-indicator';
import styles from './carousel-step-indicator-demo.module.css';
import { useCarouselStep } from '../../../hooks/use-carousel-step';

export const CarouselStepIndicatorDemo = () => {
  const CAROUSEL_LENGTH = 5; // to be replaced by length of array
  const {
    currentStep,
    nextStep,
    previousStep,
    goToStep,
    isFirstStep,
    isLastStep
  } = useCarouselStep(CAROUSEL_LENGTH, 2);

  const showPrev = () => {
    previousStep();
  };

  const showNext = () => {
    nextStep();
  };

  const goToMiddle = () => {
    goToStep(2);
  };

  if (isFirstStep) {
    console.log('First step reached!');
  }

  if (isLastStep) {
    console.log('Last step reached');
  }

  return (
    <>
      Carousel Step Indicator
      <div className={styles.demoContainer}>
        <div className={styles.testButtons}>
          <p className={styles.testButton} onClick={showPrev}>
            Prev
          </p>
          <p className={styles.testButton} onClick={goToMiddle}>
            Go To Middle
          </p>
          <p className={styles.testButton} onClick={showNext}>
            Next
          </p>
        </div>
        <div className={styles.horizontalBar}>
          <CarouselStepIndicator
            currentStep={currentStep}
            totalSteps={CAROUSEL_LENGTH}
            primaryColor="mint"
            accentColor="sage"
            orientation="horizontal"
          />
        </div>
        <div className={styles.verticalBar}>
          <CarouselStepIndicator
            currentStep={currentStep}
            totalSteps={CAROUSEL_LENGTH}
            primaryColor="mint"
            accentColor="dark-green"
            orientation="vertical"
          />
        </div>
      </div>
    </>
  );
};

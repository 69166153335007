import React from 'react';
import { DataVisualization } from './data-visualization';
import styles from './data-visualization.module.css';

export const DataVisualizationPieDemo = () => {
  return (
    <div className={`mb-sm ${styles.demo}`}>
      <DataVisualization
        type="pie"
        data={{
          percentage: 80
        }}
        description="of members see an improvement in their first year"
      />
    </div>
  );
};

import React from 'react';
import { GoopIcon } from './goop-icon';

export const GoopIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<GoopIcon iconWidth="100" />'}</p>
      <GoopIcon iconWidth="100" />
    </div>
  );
};

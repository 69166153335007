import React, { FC } from 'react';
import { baseSanityNode } from '../../content/base-sanity-node';

import { SingleQuote, SingleQuoteProps } from './single-quote';

const quote: SingleQuoteProps = {
  id: 233,
  meta: {
    name: 'Soji',
    dateJoined: 'MEMBER since 2017'
  },
  image: {
    ...baseSanityNode,
    asset: {
      ...baseSanityNode,
      url:
        'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
    },
    alt: ''
  },
  quote:
    'The hormone test that I took with my Parsley doctor really told the story of what was contributing to the fibroids. For the first time someone was explaining to me what was happening and giving me the tools to actually change the picture for myself. My life has been upgraded ... my energy is back. My vitality is back.'
};

export const SingleQuoteDemo: FC = () => {
  return (
    <div style={{ marginBottom: 60 }}>
      <h2>Single Quote</h2>
      <SingleQuote {...quote} />
    </div>
  );
};

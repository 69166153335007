import React from 'react';
import { PinterestIcon } from './pinterest-icon';

export const PinterestIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<PinterestIcon iconWidth="100" />'}</p>
      <PinterestIcon iconWidth="100" />
    </div>
  );
};

import React from 'react';
import { VogueIcon } from './vogue-icon';

export const VogueIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<VogueIcon iconWidth="100" />'}</p>
      <VogueIcon iconWidth="100" />
    </div>
  );
};

import React from 'react';
import { CheckIcon } from './check-icon';

export const CheckIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<CheckIcon iconSize="22" />'}</p>
      <CheckIcon iconWidth="100" />
    </div>
  );
};

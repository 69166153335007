import React from 'react';
import clsx from 'clsx';
import { DoubleStatisticStructuredBox } from './double-statistic-structured-box';
import data from '../../content/double-statistic-structured-box/double-statistic-structured-box.json';

export const DoubleStatisticStructuredBoxDemo = () => {
  return (
    <div className={clsx('pt-sm')}>
      <h1>Organism - structured box with double statistics</h1>
      <DoubleStatisticStructuredBox {...data} />
    </div>
  );
};

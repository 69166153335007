import React from 'react';
import { FastCompanyIcon } from './fast-company-icon';

export const FastCompanyIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<FastCompanyIcon iconWidth="100" />'}</p>
      <FastCompanyIcon iconWidth="100" />
    </div>
  );
};

import React from 'react';
import { HighlightedText } from './highlighted-text';
import { Text } from '../..';

export const HighlightedTextDemo = () => {
  return (
    <>
      <Text variant="h3a">
        some copy{' '}
        <HighlightedText variant="underline1">underline1</HighlightedText>
      </Text>
      <Text variant="h3a">
        some copy{' '}
        <HighlightedText variant="underline2">underline2</HighlightedText>
      </Text>
      <div style={{ marginTop: '90px', display: 'flex' }}>
        <Text variant="h3a">
          A <HighlightedText variant="circle1">science-backed,</HighlightedText>
        </Text>
      </div>
      <div style={{ marginTop: '90px', display: 'flex' }}>
        <Text variant="h3a">
          A <HighlightedText variant="circle2">science-backed,</HighlightedText>
        </Text>
      </div>
    </>
  );
};

import React from 'react';
import { BlogPostQuote, Text } from '../..';
import content from '../../content/blog/blog-post-quote.json';

export const BlogPostQuoteDemo = () => {
  return (
    <div className="mb-sm px-sm">
      <Text variant="h2a">Blog Post Quote</Text>
      <BlogPostQuote quote={content.quote} author={content.author} />
    </div>
  );
};

import React from 'react';
import { TodayIcon } from './today-icon';

export const TodayIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<TodayIcon iconWidth="100" />'}</p>
      <TodayIcon iconWidth="100" />
    </div>
  );
};

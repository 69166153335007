import React from 'react';
import { MinusIcon } from './minus-icon';

export const MinusIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<MinusIcon iconWidth="100" />'}</p>
      <MinusIcon iconWidth="100" />
    </div>
  );
};

import React from 'react';
import { InstagramIcon } from './instagram-icon';

export const InstagramIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<InstagramIcon iconWidth="100" />'}</p>
      <InstagramIcon iconWidth="100" />
    </div>
  );
};

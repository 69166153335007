import React from 'react';
import { StatisticCarouselProps } from './statistic-carousel';
import { DataVisualization } from '../../molecules/data-visualization/data-visualization';

export const StatisticCarouselDemoData: StatisticCarouselProps = {
  eyebrow: 'Why 9.5 out of 10 patients recommend Parsley',
  headline: 'You commit to your health and we commit to you.',
  items: [
    {
      key: 'StatisticCarouselDemoData-1',
      title: 'Feel like yourself again.',
      description:
        'Rather than simply relieving your symptoms, we treat the cause to stop them from recurring. ',
      chart: (
        <DataVisualization
          type="pie"
          data={{ percentage: 80 }}
          description="of members see an improvement in their first year"
        />
      )
    },
    {
      key: 'StatisticCarouselDemoData-2',
      title: 'Reduce the drugs.',
      description:
        'Rather than simply relieving your symptoms, we treat the cause to stop them from recurring. ',
      chart: (
        <DataVisualization
          type="pie"
          data={{ percentage: 70 }}
          description="An interesting statistic."
        />
      )
    },
    {
      key: 'StatisticCarouselDemoData-3',
      title: 'Get real support, real results.',
      description:
        'Rather than simply relieving your symptoms, we treat the cause to stop them from recurring. ',
      chart: (
        <DataVisualization
          type="pie"
          data={{ percentage: 50 }}
          description="Some other stat"
        />
      )
    }
  ]
};

export default StatisticCarouselDemoData;

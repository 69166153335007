import React from 'react';
import FooterContent from '../content/footer/footer.json';
import { ArticleCardDemo } from '../molecules/article-card/article-card-demo';
import { BarChartDemo } from '../molecules/bar-chart/bar-chart-demo';
import { BlogNavigationDemo } from '../molecules/blog-navigation/blog-navigation-demo';
import { BlogPostInlineBannerDemo } from '../molecules/blog-post-inline-banner/blog-post-inline-banner-demo';
import { BlogPostIntroDemo } from '../molecules/blog-post-intro/blog-post-intro-demo';
import { BlogPostQuoteDemo } from '../molecules/blog-post-quote/blog-post-quote-demo';
import { BlogPostTagsFooterDemo } from '../organisms/blog-post-tags-footer/blog-post-tags-footer-demo';
import { BlogAuthorBioDemo } from '../organisms/blog-author-bio/blog-author-bio-demo';
import { ButtonDemo } from '../atoms/button/button-demo';
import { CarouselStepIndicatorDemo } from '../atoms/carousel-step-indicator/carousel-step-indicator-demo';
import { CollapsibleDataListDemo } from '../molecules/collapsible-data-list/collapsible-data-list-demo';
import { ColorsDemo } from '../atoms/colors';
import {
  ComparisonTableDemo,
  ComparisonTableMembershipDemo
} from '../organisms/comparison-table/comparison-table-demo';
import { ConditionsCollapsibleDemo } from '../organisms/conditions-collapsible/conditions-collapsible-demo';
import { DoubleStatisticStructuredBoxDemo } from '../organisms/double-statistic-structured-box/double-statistic-structured-box-demo';
import { StandardCarouselDemo } from '../organisms/standard-carousel/standard-carousel-demo';
import { ConditionsModuleDemo } from '../organisms/conditions-module/conditions-module-demo';
import { ContinuedTextListDemo } from '../molecules/continued-text-list/continued-text-list-demo';
import { FaqDemo } from '../organisms/faq/faq-demo';
import { GridDemo } from '../atoms/grid';
import { HalfAndHalfDemo } from '../organisms/half-and-half/half-and-half-demo';
import { HeroDemo } from '../organisms/hero';
import { HighlightedTextDemo } from '../atoms/highlighted-text/highlighted-text-demo';
import { IconsDemo } from '../molecules/icons/icons-demo';
import { ImageDemo } from '../atoms/image/image-demo';
import { Layout } from '../layouts/main-layout';
import { Link } from '../atoms/link';
import { LocationsDemo } from '../organisms/locations/locations-demo';
import { MaskDemo } from '../atoms/mask/mask-demo';
import { NewsletterDemo } from '../organisms/newsletter/newsletter-demo';
import { ProcessCarouselDemo } from '../organisms/process-carousel/process-carousel-demo';
import { DoctorListFiltersDemo } from '../molecules/doctor-list-filters/doctor-list-filters-demo';
import { QuoteCarouselDemo } from '../organisms/quote-carousel/quote-carousel-demo';
import { ReviewDemo } from '../organisms/review/review-demo';
import { RatingDemo } from '../molecules/rating/rating-demo';
import { RoundedCheckboxDemo } from '../atoms/rounded-checkbox/rounded-checkbox-demo';
import { SingleQuoteDemo } from '../organisms/single-quote/single-quote-demo';
import { SocialLinks } from '../molecules/social-links/social-links';
import { StatisticDemo } from '../molecules/statistic/statistic-demo';
import { StructuredDemo } from '../organisms/structured/structured-demo';
import { StatisticStructuredBoxDemo } from '../organisms/statistic-structured-box/statistic-structured-box-demo';
import { TagDemo } from '../atoms/tag/tag-demo';
import { TextListDemo } from '../molecules/text-list/text-list-demo';
import { TextLockupDemo } from '../atoms/text-lockup/text-lockup-demo';
import { UtilityNavDemo } from '../organisms/utlity-nav/utility-nav-demo';
import { StatisticCarouselDemo } from '../organisms/statistic-carousel/statistic-carousel-demo';
import { DataVisualizationPieDemo } from '../molecules/data-visualization/data-visualization-pie-demo';
import { DropdownSelectionDemo } from '../molecules/dropdown-selection/dropdown-selection-demo';
import {
  NewsletterSignUpForm,
  InputPill,
  ThirdPartyQuoteCarousel,
  Module
} from '../index';
import { ProviderGridDemo } from '../organisms/provider-grid/provider-grid-demo';
import { BlogHeroDemo } from '../organisms/blog-hero/blog-hero-demo';
import { SearchBoxDemo } from '../molecules/search-box/search-box-demo';
import ExposedFilterDemo from '../molecules/exposed-filter/exposed-filter-demo';
import HiddenFilterDemo from '../molecules/hidden-filter/hidden-filter-demo';
import { ProductTileDemo } from '../organisms/product-tile/product-tile-demo';
import { TimelineCarousel } from '../organisms/timeline-carousel/timeline-carousel';
import timelineCarousel from '../content/why-it-works/timeline-carousel';
import { BottomSheetDemo } from '../organisms/bottom-sheet/bottom-sheet-demo';
import { MembershipHeroDemo } from '../organisms/membership-hero/membership-hero-demo';

export default function RedesignStyleGuide() {
  return (
    <Layout theme="darkGreen">
      <MembershipHeroDemo />
      <TimelineCarousel
        slides={timelineCarousel.items}
        heading={timelineCarousel.headline}
        description={timelineCarousel.description}
      />
      <BlogHeroDemo />
      <SearchBoxDemo />
      <BlogAuthorBioDemo />
      <TimelineCarousel
        slides={timelineCarousel.items}
        heading={timelineCarousel.headline}
        description={timelineCarousel.description}
      />
      <BarChartDemo />
      <Module theme="darkGreen">
        <ProviderGridDemo />
      </Module>
      <ConditionsModuleDemo />
      <ContinuedTextListDemo />
      <DoctorListFiltersDemo />
      <DoubleStatisticStructuredBoxDemo />
      <DropdownSelectionDemo />
      <StatisticCarouselDemo />
      <DataVisualizationPieDemo />
      <HeroDemo />
      <StatisticDemo />
      <CollapsibleDataListDemo />
      <ConditionsCollapsibleDemo />
      <HalfAndHalfDemo />
      <Link color="darkGreen" variant="u1bLink" destination="/">
        This is a link
      </Link>
      <SocialLinks socialLinks={FooterContent.social.links} />
      <ComparisonTableDemo />
      <ComparisonTableMembershipDemo />
      <StandardCarouselDemo />
      <BlogPostInlineBannerDemo />
      <BlogPostTagsFooterDemo />
      <BlogPostIntroDemo />
      <BlogPostQuoteDemo />
      <BlogNavigationDemo />
      <RoundedCheckboxDemo />
      <IconsDemo />
      <LocationsDemo />
      <ColorsDemo />
      <NewsletterDemo />
      <NewsletterSignUpForm
        ctaTrackingLabel="test tracking - demo newsletter sign up button"
        formTrackingLabel="test tracking - demo newsletter sign up form"
      />
      <InputPill inputName="test" CTA="Submit" placeholder="placeholder" />
      <FaqDemo />
      <CarouselStepIndicatorDemo />
      <ThirdPartyQuoteCarousel />
      <UtilityNavDemo />
      <HighlightedTextDemo />
      <ImageDemo />
      <ButtonDemo />
      <TextLockupDemo />
      <div className="mt-xl" />
      <GridDemo />
      <StructuredDemo />
      <StatisticStructuredBoxDemo />
      <TextListDemo />
      <MaskDemo />
      <SingleQuoteDemo />
      <QuoteCarouselDemo />
      <ReviewDemo />
      <RatingDemo />
      <TagDemo />
      <ProcessCarouselDemo />
      <ArticleCardDemo />
      <ExposedFilterDemo />
      <div className="mt-xl" />
      <HiddenFilterDemo />
      <ProductTileDemo />
      <BottomSheetDemo />
    </Layout>
  );
}

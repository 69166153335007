import React from 'react';
import { CollapsibleDataList, Text } from '../..';
import JSONData from './collapsible-data-list-content-demo.json';
import styles from '../../atoms/carousel-step-indicator/carousel-step-indicator-demo.module.css';
import { useCarouselStep } from '../../../hooks/use-carousel-step';

const CAROUSEL_LENGTH = JSONData.length;

export const CollapsibleDataListDemo = () => {
  const { currentStep, nextStep, previousStep, goToStep } = useCarouselStep(
    CAROUSEL_LENGTH,
    2
  );

  const showPrev = () => {
    previousStep();
  };

  const showNext = () => {
    nextStep();
  };

  return (
    <div id="CollapsibleDataList" className="styleGuidesection">
      <Text variant="h2a">Collapsible data list</Text>
      <div className="ml-lg">
        <CollapsibleDataList
          items={JSONData}
          activeItem={currentStep}
          onItemClick={goToStep}
        />
      </div>
      <div className={styles.testButtons}>
        <button className={styles.testButton} onClick={showPrev}>
          Prev
        </button>
        {[1, 2, 3].map((step: number) => (
          <button
            key={`step-${step}`}
            className="ml-m mr-m"
            onClick={() => goToStep(step - 1)}
          >
            {step}
          </button>
        ))}
        <button className={styles.testButton} onClick={showNext}>
          Next
        </button>
      </div>
    </div>
  );
};

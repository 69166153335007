import React, { FC } from 'react';
import styles from './colors.module.css';
import clsx from 'clsx';

const primary = ['dark-green', 'sage', 'clover', 'mint', 'ivory'] as const;
const secondary = [
  'baby-blue',
  'dark-ochre',
  'dark-purple',
  'dark-rose',
  'navy',
  'peach'
] as const;
const ui = [
  'black',
  'blush',
  'dark-gray',
  'error-red',
  'gray-nurse',
  'lavender',
  'light-gray',
  'light-text',
  'medium-gray',
  'utility-black',
  'utility-dark-gray',
  'white'
] as const;
const gradients = ['gradient-sherbed', 'gradient-green'] as const;

export type TColor =
  | typeof ui[number]
  | typeof primary[number]
  | typeof secondary[number]
  | typeof gradients[number];
export type TTextColor =
  | 'dark-green'
  | 'primary'
  | 'sage'
  | 'current'
  | typeof ui[number];
export type TButtonColor = 'darkGreen' | 'white' | 'sage';

const ColorItem: FC<{ color: TColor }> = ({ color }) => {
  return (
    <article className={styles.demo}>
      <p className={styles.demoTitle}>{color}</p>
      <div
        className={clsx(
          styles.demoColor,
          `bg-${color}`,
          'rounded-xtiny',
          'shadow-sm'
        )}
      ></div>
    </article>
  );
};
const ColorList = ({ children }) => {
  return <div className="flex flex-wrap">{children}</div>;
};

export const ColorsDemo: FC = () => {
  return (
    <section>
      <p>Primary</p>
      <ColorList>
        {primary.map(iColor => (
          <ColorItem key={iColor} color={iColor} />
        ))}
      </ColorList>
      <p>UI Colors</p>
      <ColorList>
        {ui.map(iColor => (
          <ColorItem key={iColor} color={iColor} />
        ))}
      </ColorList>
      <p>Gradients</p>
      <ColorList>
        {gradients.map(iColor => (
          <ColorItem key={iColor} color={iColor} />
        ))}
      </ColorList>
    </section>
  );
};

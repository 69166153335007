import React from 'react';
import { CircleIcon } from './circle-icon';

export const CircleIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<CircleIcon iconWidth="80" />'}</p>
      <CircleIcon iconWidth="80" />
    </div>
  );
};

import React from 'react';
import { PlayIcon } from './play-icon';

export const PlayIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<PlayIcon iconWidth="100" />'}</p>
      <PlayIcon iconWidth="100" />
    </div>
  );
};

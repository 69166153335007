import React from 'react';
import { Locations } from './locations';
import data from '../../content/locations/locations.json';

export const LocationsDemo = () => {
  return (
    <div className="pt-sm">
      <h1>Organism - Locations</h1>
      <Locations
        headline={data.headline}
        supportingCopy={data.supportingCopy}
        left={data.left}
        right={data.right}
      />
    </div>
  );
};

import React, { useState } from 'react';
import { IllustrativeIcon } from '../../molecules/icons';
import { Image } from '../../atoms/image/image';
import { Statistic } from '../../molecules/statistic/statistic';
import { ImageType } from '../../atoms/image/types';
import { Structured, StructuredProps } from './structured';
import { baseSanityNode } from '../../content/base-sanity-node';

export const StructuredDemo = () => {
  const containerStyle = { marginBottom: '50px', marginTop: '50px' };
  const titleStyle = {
    fontSize: '24px',
    marginLeft: '100px'
  };
  const [alignment, setAlignment] = useState<StructuredProps['alignment']>(
    'left'
  );

  const handleAlignmentChange = (e: any) => {
    setAlignment(e.target.value);
  };

  const items = [
    {
      id: 'structuredItem1',
      heading: 'Id vitae aliquam neque.',
      description:
        'Aliquam volutpat id eget purus viverra tincidunt id tellus pellentesque. Amet nullam faucibus feugiat fermentum at.',
      illustration: <IllustrativeIcon iconWidth={128} name={'goal'} />
    },
    {
      id: 'structuredItem2',
      heading: 'Id vitae aliquam neque.',
      description:
        'Aliquam volutpat id eget purus viverra tincidunt id tellus pellentesque. Amet nullam faucibus feugiat fermentum at.',
      illustration: (
        <Image
          asset={{
            ...baseSanityNode,
            url:
              'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
          }}
          alt=""
          type={ImageType.ROUND}
        />
      )
    },
    {
      id: 'structuredItem3',
      heading: 'Id vitae aliquam neque.',
      description:
        'Aliquam volutpat id eget purus viverra tincidunt id tellus pellentesque. Amet nullam faucibus feugiat fermentum at.',
      illustration: (
        <Statistic
          className="bg-transparent"
          textVariant={'statSmall'}
          data={{
            value: '50+'
          }}
        />
      )
    }
  ];

  return (
    <div>
      <div style={containerStyle}>
        <h2 style={titleStyle}>Structured Demo</h2>
        <Structured
          eyebrow="Lorem ipsum"
          heading="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consectetur sem nec quam feugiat porta. Nunc vitae neque nisl. Phasellus non felis pharetra."
          items={items}
          cta={{ label: 'Lorem ipsum', destination: '/' }}
          alignment={alignment}
        />
      </div>
      <div style={{ border: 'dashed 1px black' }}>
        <h2>Structured Demo Controls</h2>
        <p>Alignment:</p>
        <div
          onChange={handleAlignmentChange}
          style={{ display: 'grid', gridTemplateColumns: '20px 1fr' }}
        >
          <input
            type="radio"
            value={'left' as StructuredProps['alignment']}
            name="alignLeft"
            checked={alignment === 'left'}
          />{' '}
          Left
          <input
            type="radio"
            value={'center' as StructuredProps['alignment']}
            name="alignCenter"
            checked={alignment === 'center'}
          />{' '}
          Center
        </div>
      </div>
    </div>
  );
};

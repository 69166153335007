import React from 'react';
import { PlusIcon } from './plus-icon';

export const PlusIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<PlusIcon iconWidth="100" />'}</p>
      <PlusIcon iconWidth="100" />
    </div>
  );
};

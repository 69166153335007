import React from 'react';
import { DropdownSelection, Option } from './../../';
import {
  useSelectOptions,
  optionsToString
} from '../../../hooks/use-select-options';
import { useMultipleSelectOptions } from '../../../hooks/use-multiple-select-options';
import { useOnToggleScrolling } from '../../../hooks/use-on-toggle-scrolling';

const options = [
  { label: 'Doctor and Health Coaches', value: '1' },
  { label: 'Doctors', value: '2' },
  { label: 'Coaches', value: '3' }
];

const optionsCategory = [
  {
    label: 'Recipes & Nutrition',
    items: [
      { label: 'Recipies', value: 'Recipies' },
      { label: 'Nutrition', value: 'Nutrition' }
    ]
  },
  {
    label: 'Guides',
    items: [{ label: 'Optimization', value: 'Optimization' }]
  }
];

export const DropdownSelectionDemo = () => {
  const [isOpen, onToggleOpen] = useOnToggleScrolling(false);
  const [isCategoryOpen, onToggleIsCategoryOpen] = useOnToggleScrolling(false);
  const [_options, selectedOptions, onSelectOption] = useSelectOptions(
    options,
    [options[0]],
    true
  );
  const [
    _optionsCategory,
    selectedCategoryOptions,
    onSelectCategoryOption
  ] = useMultipleSelectOptions(
    (i: any) => i.category,
    optionsCategory,
    [options[0]],
    false
  );
  const headingLabelSelected =
    selectedOptions.length && optionsToString(selectedOptions);
  const headingCategoryLabelSelected =
    selectedCategoryOptions.length && optionsToString(selectedCategoryOptions);

  return (
    <div className="bg-dark-green p-sm">
      {'single selection'}
      <DropdownSelection
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={headingLabelSelected}
        ctaTrackingLabel="test tracking - dropdown"
      >
        {_options?.map((o, i: number) => (
          <Option
            option={o}
            key={`option-${o.label}-${i}`}
            onSelectOption={() => onSelectOption(o)}
          />
        ))}
      </DropdownSelection>
      {'multiple selection'}
      <div className="ph-mt-10">
        <DropdownSelection
          isOpen={isCategoryOpen}
          onToggleOpen={onToggleIsCategoryOpen}
          heading={headingCategoryLabelSelected}
          ctaTrackingLabel="test tracking - dropdown"
        >
          {_optionsCategory?.map((c, i: number) => (
            <div key={i}>
              <h4>{c.label}</h4>
              <div key={`options-${i}`}>
                {c.items.map((o: any, i: number) => (
                  <Option
                    option={o}
                    key={`option-${o.label}-${i}`}
                    onSelectOption={() => onSelectCategoryOption(o)}
                  />
                ))}
              </div>
            </div>
          ))}
        </DropdownSelection>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { HalfAndHalf } from './half-and-half';
import JSONData from '../../content/half-and-half/half-and-half-a-best-in-class.json';
import { Text } from '../../atoms/text/text';
import {
  TextLockup,
  TextLockupProps
} from '../../atoms/text-lockup/text-lockup';
import { Button } from '../../atoms/button/button';

const TextLockupContent: FC<TextLockupProps> = ({ alignment }) => (
  <TextLockup alignment={alignment}>
    <Text variant="p3b" className="mb-xxs" color="dark-green">
      {JSONData.textContent.eyebrow}
    </Text>
    <Text variant="h2a" className="mb-sm" color="dark-green">
      {JSONData.textContent.headline}
    </Text>
    <Text variant="p2a" className="mb-xs" color="dark-green">
      {JSONData.textContent.description}
    </Text>
    <div className="text-lockup-buttons-container">
      {JSONData.textContent.primaryCtaText && (
        <Button
          onClick={() => null}
          variant="filled"
          color="darkGreen"
          trackingLabel="test tracking"
        >
          {JSONData.textContent.primaryCtaText}
        </Button>
      )}
      {JSONData.textContent.secondaryCtaText && (
        <Button
          onClick={() => null}
          variant="filled"
          color="white"
          trackingLabel="test tracking"
        >
          {JSONData.textContent.secondaryCtaText}
        </Button>
      )}
    </div>
  </TextLockup>
);

export const HalfAndHalfDemo: FC = () => (
  <>
    <HalfAndHalf
      image={JSONData.left.image}
      imgBgColor={JSONData.left.imgBgColor}
      bgColor={JSONData.left.bgColor}
      imagePosition={JSONData.left.imagePosition}
      fullImage={JSONData.left.fullImage}
      inset={JSONData.left.inset}
    >
      <TextLockupContent alignment="center" />
    </HalfAndHalf>
    <HalfAndHalf
      image={JSONData.right.image}
      imgBgColor={JSONData.right.imgBgColor}
      bgColor={JSONData.right.bgColor}
      imagePosition={JSONData.right.imagePosition}
      fullImage={JSONData.right.fullImage}
      inset={JSONData.right.inset}
    >
      <TextLockupContent alignment="center" />
    </HalfAndHalf>
    <HalfAndHalf
      image={JSONData.inset.image}
      imgBgColor={JSONData.inset.imgBgColor}
      bgColor={JSONData.inset.bgColor}
      imagePosition={JSONData.inset.imagePosition}
      fullImage={JSONData.inset.fullImage}
      inset={JSONData.inset.inset}
    >
      <TextLockupContent alignment="center" />
    </HalfAndHalf>
    <HalfAndHalf
      image={JSONData.imgBackground.image}
      imgBgColor={JSONData.imgBackground.imgBgColor}
      bgColor={JSONData.imgBackground.bgColor}
      imagePosition={JSONData.imgBackground.imagePosition}
      fullImage={JSONData.imgBackground.fullImage}
      inset={JSONData.imgBackground.inset}
    >
      <TextLockupContent alignment="center" />
    </HalfAndHalf>
  </>
);

import React, { FC } from 'react';
import { Text, Button } from '../../';
import './product-tile.scss';
import clsx from 'clsx';

interface ProductTileProps {
  image: { src: string; alt: string };
  title: string;
  price: string;
  single?: boolean;
  ctaTrackingLabel: string;
}

export const ProductTile: FC<ProductTileProps> = ({
  image,
  title,
  price,
  single,
  ctaTrackingLabel
}) => {
  image;
  return (
    <div
      className={clsx('flex flex-row product-tile-container', {
        single,
        multiple: !single
      })}
    >
      <img src={image.src} alt={image.alt} className="product-tile-image" />

      <div className="flex flex-col justify-center product-tile-info">
        <Text variant="p1a" color="dark-green" className="product-tile-title">
          {title}
        </Text>
        <Text variant="p2a" color="dark-green" className="product-tile-price">
          ${price}
        </Text>
        <Button
          size="small"
          variant="filled"
          color="sage"
          className="product-tile-shop-now-button"
          trackingLabel={ctaTrackingLabel}
        >
          Shop now
        </Button>
      </div>
    </div>
  );
};

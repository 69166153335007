import React from 'react';
import { ExposedFilterPanel } from '../../molecules/exposed-filter/exposed-filter-panel';
import { useMultipleSelectOptions } from '../../../hooks/use-multiple-select-options';
import { useSelectOptions } from '../../../hooks/use-select-options';
import { TSortBy } from '../../molecules/exposed-filter/exposed-filter-panel';

export default function ExposedFilterDemo() {
  const sortOptionItems = [
    { label: 'Newest', value: 'Newest' },
    { label: 'Most popular', value: 'Most popular' },
    { label: 'Medically Viewed', value: 'Medically Viewed' }
  ];

  const filters = [
    {
      label: 'Hormonal Health',
      items: [
        { label: 'Autoimmune & Inflammation', value: 'Autoimmune' },
        { label: 'Digestive Issues', value: 'Digestive' }
      ]
    },

    {
      label: 'Menopause',
      items: [
        { label: 'Fertility', value: 'Fertility' },
        { label: 'Pregnancy', value: 'Pregnancy' }
      ]
    }
  ];

  const [
    sortOptions,
    selectedSortOptions,
    onSelectSortOptions
  ] = useSelectOptions<TSortBy>(sortOptionItems, [sortOptionItems[0]], true);

  const [
    filterOptions,
    selectedFilterOptions,
    onSelectFilterOptions,
    clearFilterSelection
  ] = useMultipleSelectOptions((i: any) => i.category, filters, [], false);

  return (
    <div>
      <h4>Exposed Filter Demo</h4>
      <ExposedFilterPanel
        title="Sort By"
        filters={filterOptions}
        selectedFilter={selectedFilterOptions}
        onSelectFilter={onSelectFilterOptions}
        sortBy={sortOptions}
        selectedSortBy={selectedSortOptions}
        onSelectSortBy={onSelectSortOptions}
        onClearFilter={clearFilterSelection}
      />
    </div>
  );
}

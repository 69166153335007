import React from 'react';
import { BarChart } from './bar-chart';
import styles from './bar-chart.module.css';

export const BarChartDemo = () => {
  const data = [
    { key: '1barchart', title: 'YEAR 0', percentage: 100 },
    { key: '2barchart', title: 'YEAR 1', percentage: 30 }
  ];

  return (
    <div className={`mb-md ml-sm ${styles.demoContainer}`}>
      <BarChart textVariant="p3a" name="SPECIALIST REFERRALS" data={data} />
    </div>
  );
};

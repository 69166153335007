import React from 'react';
import clsx from 'clsx';
import { StatisticStructuredBox } from './statistic-structured-box';
import data from '../../content/statistic-structured-box/statistic-structured-box.json';

export const StatisticStructuredBoxDemo = () => {
  return (
    <div className={clsx('pt-sm')}>
      <h1>Organism - structured box with statistic</h1>
      <StatisticStructuredBox {...data} />
    </div>
  );
};

import React, { FC } from 'react';
import { ContinuedTextList } from './continued-text-list';
import { Text } from '../..';

const dataList = Array(5).fill('lorem ipsum');

export const ContinuedTextListDemo: FC = () => {
  return (
    <div id="ContinuedTextList" className="my-lg">
      <Text variant="h2a">ContinuedTextList</Text>
      <ContinuedTextList
        items={dataList}
        color="dark-green"
        className="my-lg"
      />
    </div>
  );
};

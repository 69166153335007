import React from 'react';
import { HighlightedText } from '../..';

const content = {
  headline: (
    <>
      A{' '}
      <HighlightedText
        variant="underline3"
        className="ha2-highlighted-underline-3"
      >
        year
      </HighlightedText>{' '}
      with Parsley Health
    </>
  ),
  description:
    'Over the course of your year-long membership, we uncover the full picture of your health and get you back in balance.',
  items: [
    {
      id: 0,
      header: '',
      title: '',
      benefits: []
    },
    {
      id: 1,
      header: 'Week 1',
      title: 'Getting Started',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 2,
      header: 'Month 1',
      title: 'Advanced Testing',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 3,
      header: 'Month 2',
      title: 'Personalized Plan',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 4,
      header: 'Ongoing',
      title: 'Support and Connection',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 5,
      header: 'Example Slide 5',
      title: 'Getting Started',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 6,
      header: 'Example Slide 6',
      title: 'Getting Started',
      benefits: [
        'Sign up for membership',
        'Complete your intake forms',
        'Choose a specific provider or the earliest available'
      ]
    },
    {
      id: 7,
      header: '',
      title: '',
      benefits: []
    }
  ]
};

export default content;

import React from 'react';
import { UtilityNav } from './utlity-nav';

export const UtilityNavDemo: React.FC = () => {
  return (
    <div>
      <UtilityNav />
    </div>
  );
};

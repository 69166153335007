import React from 'react';
import { CaretIcon } from './caret-icon';

export const CaretIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<CaretIcon direction="left" iconWidth="100" />'}</p>
      <CaretIcon direction="left" iconWidth="100" />
      <p>{'<CaretIcon iconWidth="30px" direction="right" color="clover" />'}</p>
      <CaretIcon iconWidth="30px" direction="right" color="clover" />
      <p>{'<CaretIcon iconWidth="40" direction="up" color="darkGreen" />'}</p>
      <CaretIcon iconWidth="40" direction="up" color="darkGreen" />
      <p>{'<CaretIcon iconWidth="40" direction="down" color="darkGreen" />'}</p>
      <CaretIcon iconWidth="40" direction="down" color="darkGreen" />
    </div>
  );
};

import React from 'react';
import { HoverCircleIcon } from './hover-circle-icon';

export const HoverCircleIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<HoverCircleIcon iconWidth="80" />'}</p>
      <HoverCircleIcon iconWidth="80" />
    </div>
  );
};

import React from 'react';
import { BlogPostIntro, Text } from '../..';
import content from '../../content/blog/blog-post-intro.json';

export const BlogPostIntroDemo = () => {
  return (
    <div className="mb-sm px-sm">
      <Text variant="h2a">Blog Post Intro</Text>
      <BlogPostIntro intro={content.intro} />
    </div>
  );
};

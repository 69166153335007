import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from '../../molecules/icons';
import useOnClickOutside from '../../../hooks/use-on-click-outside';
import './bottom-sheet.scss';
import { SlideDown } from '../../atoms/slide-down/slide-down';
interface BottomSheetProp {
  children?: React.ReactNode;
  isOpen?: boolean;
  bottomSheetDivClass?: string;
  /*
    The optional parameter `intentional` is intended to indicate that the
    close action was intentional and not by clicking outside of the modal
  */
  onClose(intentional?: boolean): void;
}

const portalRoot =
  typeof document !== `undefined` ? document.getElementById('portal') : null;

export const BottomSheet: React.FC<BottomSheetProp> = ({
  isOpen = false,
  bottomSheetDivClass = 'bottom-sheet-root',
  children,
  onClose
}) => {
  const wrapperRef = useRef(null);
  const [container, setContainer] = useState(null);

  useEffect(() => {
    const el = document.createElement('div');
    el.classList.add(bottomSheetDivClass);
    setContainer(el);
  }, [bottomSheetDivClass]);

  useEffect(() => {
    if (container) {
      portalRoot.appendChild(container);
    }
    return () => {
      if (container) {
        portalRoot.removeChild(container);
      }
    };
  }, [container]);

  const handleClickOutside = (): void => {
    onClose();
  };

  useOnClickOutside(wrapperRef, handleClickOutside);

  const portal =
    portalRoot && container
      ? createPortal(
          <div className="bottom-sheet-box">
            <SlideDown show>
              <div ref={wrapperRef} className="bottom-sheet-wrapper">
                <div className="text-right">
                  <CloseIcon
                    style={{ fontSize: '30' }}
                    onClick={() => onClose(true)}
                  />
                </div>
                {children}
              </div>
            </SlideDown>
          </div>,
          container
        )
      : null;

  return isOpen ? portal : null;
};

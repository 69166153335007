import React, { FC } from 'react';
import { ConditionsCollapsible } from './conditions-collapsible';
import data from '../../content/conditions-collapsible/conditions-collapsible.json';

export const ConditionsCollapsibleDemo: FC = () => {
  return (
    <ConditionsCollapsible
      conditionsArray={data.conditionsArray}
      conditionsSubtitle={data.conditionsSubtitle}
      heading={data.conditionsHeading}
      halfAndHalfProps={data.imageData}
    />
  );
};

import React from 'react';
import { WellGoodIcon } from './well-good-icon';

export const WellGoodIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<WellGoodIcon iconWidth="100" />'}</p>
      <WellGoodIcon iconWidth="100" />
    </div>
  );
};

import React from 'react';
import { MembershipHero } from './membership-hero';
import data from '../../content/membership/hero.json';
import './membership-hero.scss';

export const MembershipHeroDemo = () => {
  return (
    <MembershipHero
      leftImage={data.leftImage}
      rightImage={data.rightImage}
      className="mb-sm"
      textLockup={data.textContent}
    />
  );
};

import React from 'react';
import { IllustrativeIcon } from './illustrative-icon';

export const IllustrativeIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<IllustrativeIcon name="centers" />'}</p>
      <IllustrativeIcon name="centers" />
      <p>{'<IllustrativeIcon name="certified" />'}</p>
      <IllustrativeIcon name="certified" />
      <p>{'<IllustrativeIcon name="connection" />'}</p>
      <IllustrativeIcon name="connection" />
      <p>{'<IllustrativeIcon name="cook" />'}</p>
      <IllustrativeIcon name="cook" />
      <p>{'<IllustrativeIcon name="doctor" />'}</p>
      <IllustrativeIcon name="doctor" />
      <p>{'<IllustrativeIcon name="expert" />'}</p>
      <IllustrativeIcon name="expert" />
      <p>{'<IllustrativeIcon name="functional" />'}</p>
      <IllustrativeIcon name="functional" />
      <p>{'<IllustrativeIcon name="goal" />'}</p>
      <IllustrativeIcon name="goal" />
      <p>{'<IllustrativeIcon name="insurance" />'}</p>
      <IllustrativeIcon name="insurance" />
      <p>{'<IllustrativeIcon name="investigate" />'}</p>
      <IllustrativeIcon name="investigate" />
      <p>{'<IllustrativeIcon name="listen" />'}</p>
      <IllustrativeIcon name="listen" />
      <p>{'<IllustrativeIcon name="locations" />'}</p>
      <IllustrativeIcon name="locations" />
      <p>{'<IllustrativeIcon name="movement" />'}</p>
      <IllustrativeIcon name="movement" />
      <p>{'<IllustrativeIcon name="nutrition" />'}</p>
      <IllustrativeIcon name="nutrition" />
      <p>{'<IllustrativeIcon name="online" />'}</p>
      <IllustrativeIcon name="online" />
      <p>{'<IllustrativeIcon name="personal" />'}</p>
      <IllustrativeIcon name="personal" />
      <p>{'<IllustrativeIcon name="plan" />'}</p>
      <IllustrativeIcon name="plan" />
      <p>{'<IllustrativeIcon name="read" />'}</p>
      <IllustrativeIcon name="read" />
      <p>{'<IllustrativeIcon name="results" />'}</p>
      <IllustrativeIcon name="results" />
      <p>{'<IllustrativeIcon name="schedule" />'}</p>
      <IllustrativeIcon name="schedule" />
      <p>{'<IllustrativeIcon name="secure" />'}</p>
      <IllustrativeIcon name="secure" />
      <p>{'<IllustrativeIcon name="supplements" />'}</p>
      <IllustrativeIcon name="supplements" />
      <p>{'<IllustrativeIcon name="teamCollab" />'}</p>
      <IllustrativeIcon name="teamCollab" />
      <p>{'<IllustrativeIcon name="testing" />'}</p>
      <IllustrativeIcon name="testing" />
      <p>{'<IllustrativeIcon name="watch" />'}</p>
      <IllustrativeIcon name="watch" />
    </div>
  );
};

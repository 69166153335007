import React from 'react';
import { ComparisonTable } from '../..';
import content from '../../content/why-it-works/comparison-table.json';
import contentMembership from '../../content/membership/comparison-table.json';

export const ComparisonTableDemo = () => {
  return (
    <ComparisonTable textLockup={content.textLockup} table={content.table} />
  );
};

export const ComparisonTableMembershipDemo = () => {
  return (
    <ComparisonTable
      textLockup={contentMembership.textLockup}
      table={contentMembership.table}
    />
  );
};

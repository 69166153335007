import React from 'react';
import { SearchBox } from './search-box';

export const SearchBoxDemo = () => {
  const items = [
    { id: 1, text: 'Aerospace' },
    { id: 2, text: 'Mechanical' },
    { id: 3, text: 'Civil' },
    { id: 4, text: 'Biomedical' },
    { id: 5, text: 'Nuclear' }
  ];
  return (
    <div className="m-md">
      <SearchBox
        items={items}
        placeholder="Search the blog"
        id="demo search"
        ctaTrackingLabel="test - search box demo cta"
      />
    </div>
  );
};

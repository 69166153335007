import React from 'react';

import { Tag } from './tag';
import { Text } from '../../atoms/text/text';

export const TagDemo = () => {
  return (
    <div className="m-md flex-center" style={{ height: 100 }}>
      <div className="m-xs">
        <Text variant="h7a">Tags</Text>
      </div>
      <div className="flex-center">
        <Tag destination="" variant="mint">
          Health Concerns
        </Tag>
        <Tag destination="" variant="baby-blue">
          Health Concerns
        </Tag>
        <Tag destination="" variant="light-gray">
          Health Concerns
        </Tag>
      </div>
    </div>
  );
};

import React from 'react';
import { TwitterIcon } from './twitter-icon';

export const TwitterIconDemo: React.FC = () => {
  return (
    <div>
      <p>{'<TwitterIcon iconWidth="100" />'}</p>
      <TwitterIcon iconWidth="100" />
    </div>
  );
};

import React, { useState } from 'react';
import { TextList } from './text-list';
import { Text } from '../../atoms/text/text';

export const TextListDemo = () => {
  const list = ['Lorem ipsum', 'Lorem ipsum 2', 'Lorem ipsum 3'];
  const [active, setActive] = useState(0);

  const handleActive = (index: number) => {
    setActive(index);
  };

  return (
    <div className="m-md">
      <div className="m-xs">
        <Text variant="h7a">Text List</Text>
      </div>
      <TextList textList={list} active={active} onItemClick={handleActive} />
    </div>
  );
};

import React, { FC } from 'react';
import { baseSanityNode } from '../../content/base-sanity-node';
import { ProviderGrid } from './provider-grid';

const Providers = [
  {
    id: '1',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller',
    acceptingPatients: true
  },
  {
    id: '2',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '3',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '4',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '5',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '6',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '7',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '8',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  },
  {
    id: '9',
    profileImage: {
      ...baseSanityNode,
      asset: {
        ...baseSanityNode,
        url:
          'https://images.unsplash.com/photo-1634827023822-844b925ddced?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=988&q=80'
      },
      alt: 'This is a foto'
    },
    role: 'Doctor',
    firstName: 'Christopher Coller'
  }
];

export const ProviderGridDemo: FC = () => {
  return (
    <div className="full-w">
      <ProviderGrid
        providers={Providers}
        defaultLimit={8}
        ctaTrackingLabelLess="demo tracking provider grid less"
        ctaTrackingLabelMore="demo tracking provider grid more"
      />
    </div>
  );
};

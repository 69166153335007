import React from 'react';
import { Newsletter, Text, Row, Column } from '../..';
import content from '../../content/blog/newsletter.json';

export const NewsletterDemo = () => {
  return (
    <div className="mb-sm">
      <Text variant="h2a">Newsletter</Text>
      <Newsletter
        eyebrow={content.eyebrow}
        headline={content.headline}
        copy={content.copy}
        dropdown={content.dropdown}
        variant="full"
        ctaTrackingLabel={content.ctaTrackingLabel}
        formTrackingLabel={content.formTrackingLabel}
      />
      <Row cols={[23, 11]} offset={1} className="mt-sm">
        <Column>
          <Newsletter
            eyebrow={content.eyebrow}
            headline={content.headline}
            copy={content.copy}
            dropdown={content.dropdown}
            variant="half"
            ctaTrackingLabel={content.ctaTrackingLabel}
            formTrackingLabel={content.formTrackingLabel}
          />
        </Column>
      </Row>
    </div>
  );
};
